import * as React from 'react';

import SecondaryPageLayout from '../components/secondary-page-layout';
import Meta from '../components/meta';
import {DataProtectionPageTitle, DataProtectionPageContent} from '../localisation';
import { Url } from '../url';

import * as classes from './data-protection.module.css';

function DataProtection() {
  return (
    <SecondaryPageLayout>
      <Meta
        title={DataProtectionPageTitle}
        url={Url.DataProtection}
      />
      <div className={classes.content}>
        <h1>{DataProtectionPageTitle}</h1>

        <DataProtectionPageContent />
      </div>

    </SecondaryPageLayout>
  );
}


export default DataProtection;
